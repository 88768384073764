import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { gameInfos, gamePath, menuBarInfos } from '../common-info';

@Component({
  selector: 'app-app-description',
  templateUrl: './app-description.component.html',
  styleUrls: ['./app-description.component.css']
})
export class AppDescriptionComponent implements OnInit {
  bannerStatus = menuBarInfos.game;
  gameInfo;
  icon;
  gamePath = gamePath;
  gameScreenshots : string[]=[];
  constructor(private routeInfo: ActivatedRoute) {
    //let nId = this.routeInfo.snapshot.queryParams.id;
    let sName = this.routeInfo.snapshot.queryParams.name;
    for(let i = 0; i < gameInfos.length; i++){
      let gameInfo = gameInfos[i];
      if(sName == gameInfo.data_name){
        this.gameInfo = gameInfo;
        break;
      }
    }
    this.icon = gamePath+this.gameInfo.data_name+"/icon.png";
    for(let i = 0; i < this.gameInfo.data_screenshots_paths.length; i++){
      this.gameScreenshots.push(gamePath + this.gameInfo.data_name + "/" +this.gameInfo.data_screenshots_paths[i]);
    }
  }

  fileDown(fileUrl:string){
    //下载
    //let url = window.location.host + '/' + this.gameInfo.data_name + '/' + fileUrl;//  +fileUrl;
    //let url = gamePath + this.gameInfo.data_name + '/' + fileUrl;
    let url = fileUrl;
    window.open(url);
    //window.location.href = url;
    //URL.revokeObjectURL(url);
  }

  ngChange(){
  }

  ngOnInit(): void {
  }

}
