import { AfterContentInit, Component, OnInit } from '@angular/core';
import {gameInfos, gamePath, topGamesIDs} from '../common-info'
declare var $: any;
@Component({
  selector: 'app-top-games',
  templateUrl: './top-games.component.html',
  styleUrls: ['./top-games.component.css']
})

export class TopGamesComponent implements OnInit, AfterContentInit {
  //topGamesInfos = topGamesInfos;
  topGamesInfos=[];
  gameInfos=gameInfos;
  //topGamesIndexs : number[] = [];
  constructor() {
      for(let i = 0; i < topGamesIDs.length; i++){
        let nID : number = topGamesIDs[i];
        let topGameInfo = {         
                img_src:"./assets/images/t1.jpg",
                 name:'111Action Games',
                 description:'111Nulla elementum nunc tempus.',
                 to_game_id:1,
                 to_game_name:""
                };
        for(let j = 0; j < gameInfos.length; j++){
          let gameInfo = gameInfos[j];
          if(nID == gameInfo.data_id){
            topGameInfo.to_game_id = nID;
            topGameInfo.img_src = gamePath + gameInfo.data_name + '/' + gameInfo.data_img_src,
            topGameInfo.name = gameInfo.data_title;
            topGameInfo.description = gameInfo.data_subtitle;
            topGameInfo.to_game_name = gameInfo.data_name;
            this.topGamesInfos.push(topGameInfo);
            break;
          }
        }
      }
   }
   
  ngAfterContentInit(): void {
    $(function(){
      $("#flexiselDemo1").flexisel({
      visibleItems: 4,
      animationSpeed: 1000,
      autoPlay: true,
      autoPlaySpeed: 3000,    		
      pauseOnHover:true,
      enableResponsiveBreakpoints: true,
      responsiveBreakpoints: { 
        portrait: { 
          changePoint:480,
          visibleItems: 1
        }, 
        landscape: { 
          changePoint:640,
          visibleItems: 2
        },
        tablet: { 
          changePoint:768,
          visibleItems: 3
        }
      }
    });

  });
  }

  ngOnInit(): void {
  }

}
