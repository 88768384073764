import { Component, OnInit } from '@angular/core';
import { menuBarInfos, termsInfos } from '../common-info';

@Component({
  selector: 'app-app-terms',
  templateUrl: './app-terms.component.html',
  styleUrls: ['./app-terms.component.css']
})
export class AppTermsComponent implements OnInit {
  bannerStatus = menuBarInfos.terms;
  constructor() { }

 termsInfos=termsInfos;
  ngOnInit(): void {
  }

}
