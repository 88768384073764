<app-top-bar [bannerStatus]='bannerStatus'></app-top-bar>
<div id="ember232" class="animation-wrapper">
    <div id="ember234">
        <section id="ember235" class="l-content-width section section--hero product-hero">
            <div class="l-row">
                <div class="l-column small-5 medium-4 large-3 small-valign-top">
                    <picture id="ember236"
                        class="we-artwork we-artwork--fullwidth we-artwork--ios-app-icon"
                        data-test-product-hero-artwork="">
                        <!--图片标题-->
                        <img class="we-artwork__image ember236"
                            src={{icon}}
                            style="" alt="" height="246" width="246">
                        <!--图片标题 end-->
                    </picture>
                </div>

                <div class="l-column small-7 medium-8 large-9 small-valign-top">
                    <header class="product-header app-header product-header--padded-start" role="banner">
                        <h1 class="product-header__title" data-test-product-name="">
                            {{gameInfo.data_title}}
                        </h1>

                        <h2 class="product-header__subtitle" data-test-product-subtitle="">
                            {{gameInfo.data_subtitle}}</h2>

                        <div class="m-download" id="js_download">
                            <a class="android" href="javascript:;" (click)="fileDown(gameInfo.data_android_url)">下载Android版</a>
                            <a class="ios" href="javascript:;" (click)="fileDown(gameInfo.data_ios_url)">下载iPhone版</a>
                        </div>
                    </header>
                </div>
            </div>
        </section>

        <section class="l-content-width section section--bordered">
            <div class="section__nav section__nav--align-start">
                <h2 class="section__headline" data-test-app-screenshots-title="">Screenshots</h2>
            </div>
            <div id="ember249" class="we-screenshot-viewer">
                <div class="we-screenshot-viewer__screenshots">
                    <ul class="l-row l-row--peek we-screenshot-viewer__screenshots-list">
                        <li class="l-column small-4 medium-4 large-4" data-test-we-screenshot-viewer-row="" *ngFor="let imgPath of gameScreenshots">
                            <picture id="ember251"
                                class="we-artwork we-artwork--screenshot-platform-ipad">
                                <img class="we-artwork__image ember251"
                                    src={{imgPath}}
                                    style="background-color: #846514;" alt="" height="857" width="643">   <!---->
                                    <style>
                                        .ember251,
                                        #ember251::before {
                                            width: auto;
                                            height: auto;
                                            max-width: 643px;
                                            max-height: 857px;
                                        }
    
                                        .ember251::before {
                                            padding-top: 133.28149300155522%;
                                        }
    
                                        .ember251,
                                        #ember251::before {
                                            width: auto;
                                            height: auto;
                                            max-width: 643px;
                                            max-height: 857px;
                                        }
    
                                        .ember251::before {
                                            padding-top: 133.28149300155522%;
                                        }
    
                                        @media (min-width: 735px) {
    
                                            .ember251,
                                            #ember251::before {
                                                width: auto;
                                                height: auto;
                                                max-width: 217px;
                                                max-height: 289px;
                                            }
    
                                            .ember251::before {
                                                padding-top: 133.17972350230414%;
                                            }
                                        }
    
                                        @media (min-width: 735px) {
    
                                            .ember251,
                                            #ember251::before {
                                                width: auto;
                                                height: auto;
                                                max-width: 217px;
                                                max-height: 289px;
                                            }
    
                                            .ember251::before {
                                                padding-top: 133.17972350230414%;
                                            }
                                        }
    
                                        @media (min-width: 1069px) {
    
                                            .ember251,
                                            #ember251::before {
                                                width: auto;
                                                height: auto;
                                                max-width: 313px;
                                                max-height: 417px;
                                            }
    
                                            .ember251::before {
                                                padding-top: 133.22683706070288%;
                                            }
                                        }
    
                                        @media (min-width: 1069px) {
    
                                            .ember251,
                                            #ember251::before {
                                                width: auto;
                                                height: auto;
                                                max-width: 313px;
                                                max-height: 417px;
                                            }
    
                                            .ember251::before {
                                                padding-top: 133.22683706070288%;
                                            }
                                        }
                                    </style>
                                    <!-- <style>
                                        .ember251,
                                        #ember251::before {
                                            width: 643px;
                                            height: 857px;
                                        }
    
                                        .ember251::before {
                                            padding-top: 133.28149300155522%;
                                        }
    
                                        .ember251,
                                        #ember251::before {
                                            width: 643px;
                                            height: 857px;
                                        }
    
                                        .ember251::before {
                                            padding-top: 133.28149300155522%;
                                        }
    
                                        @media (min-width: 735px) {
    
                                            .ember251,
                                            #ember251::before {
                                                width: 217px;
                                                height: 289px;
                                            }
    
                                            .ember251::before {
                                                padding-top: 133.17972350230414%;
                                            }
                                        }
    
                                        @media (min-width: 735px) {
    
                                            .ember251,
                                            #ember251::before {
                                                width: 217px;
                                                height: 289px;
                                            }
    
                                            .ember251::before {
                                                padding-top: 133.17972350230414%;
                                            }
                                        }
    
                                        @media (min-width: 1069px) {
    
                                            .ember251,
                                            #ember251::before {
                                                width: 313px;
                                                height: 417px;
                                            }
    
                                            .ember251::before {
                                                padding-top: 133.22683706070288%;
                                            }
                                        }
    
                                        @media (min-width: 1069px) {
    
                                            .ember251,
                                            #ember251::before {
                                                width: 313px;
                                                height: 417px;
                                            }
    
                                            .ember251::before {
                                                padding-top: 133.22683706070288%;
                                            }
                                        }
                                    </style> -->
                            </picture>
                        </li>

                    </ul>
                </div>
            </div>
        </section>

        <section class="l-content-width section section--bordered">
            <div class="l-row">
                <div class="l-column small-12">
                    <h2 class="section__headline">Information</h2>
                    <p style="text-indent:2em;" *ngFor="let content of gameInfo.data_description; let niiIndex=index">{{content}}</p>                    
                </div>
            </div>
        </section>
    </div>
</div>

<app-bottom-bar></app-bottom-bar>