import { PlatformLocation } from '@angular/common';
import { Component, Input, OnInit} from '@angular/core';
import { topHomeInfos } from '../common-info';
declare var $:any;

@Component({
  selector: 'app-top-home-bar',
  templateUrl: './top-home-bar.component.html',
  styleUrls: ['./top-home-bar.component.css']
})
export class TopHomeBarComponent implements OnInit {
  topHomeInfo = topHomeInfos;
  @Input() bannerStatus:any;
  statu;
  constructor(location1: PlatformLocation) { 
    location1.onPopState(()=>{
      location.reload();
  });
}

  ngOnChanges(){
    this.statu = this.bannerStatus;
  }
  onReshuff(){
    setTimeout(function(){
      //$("#slider").load(location.href + '#slider');
      location.reload();
    },1);
  }

  ngOnInit(): void {
    $( "span.menu" ).click(function() {
      $( "ul.nav1" ).slideToggle( 300, function() {
      // Animation complete.
        //$("#slider").load(location.href + '#slider');
      });
      });
    $(function(){
      $('#slider').responsiveSlides({
        auto:true,
        nav: false,
        speed: 500,
        namespace: "callbacks",
        pager:true,
      });
    });

  }


}
