import { Component, OnInit } from '@angular/core';
import {gameInfos, gamePath, menuBarInfos} from '../common-info';

declare var $: any;
declare var Grid: any;
//declare var helium: any;
@Component({
  selector: 'app-app-games',
  templateUrl: './app-games.component.html',
  styleUrls: ['./app-games.component.css']
})
export class AppGamesComponent implements OnInit {
  bannerStatus = menuBarInfos.game;
  constructor() { 
    
  }
  gameInfos = gameInfos;
  gamePath = gamePath;
  ngOnInit(): void {
      $(function() {
        Grid.init();
        //helium.init();
      });
  }
  onReshuff(){
    setTimeout(function(){
      //$("#og-grid").load(location.href + '#og-grid');
      location.reload();
    },1);
  }
}
