
<div class="content">
    <div class="container">
        <div class="top-games">
            <h3>Top Games</h3>
            <span></span>
        </div>
        <div class="top-game-grids">
            <ul id="flexiselDemo1">
                <li *ngFor="let topgameinfo of topGamesInfos">
                    <div class="game-grid">
                        <h4 style="overflow: hidden;text-overflow: ellipsis;white-space: nowrap;">{{topgameinfo.name}}</h4>
                        <p>{{topgameinfo.description}}</p>
                        <a routerLink="/description" routerLinkActive="router-link-active" (click)='onReshuff()' [queryParams]="{name:topgameinfo.to_game_name}">
                            <img src="{{topgameinfo.img_src}}" class="img-responsive" alt=""/></a>                    
                    </div>	
                </li>				 				 	
            </ul>
            <!--
            <script type="text/javascript">

           </script>
           <script type="text/javascript" src="js/jquery.flexisel.js"></script>	
           -->
        </div>
    </div>
</div>