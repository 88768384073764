<app-top-bar [bannerStatus]='bannerStatus'></app-top-bar>

<!--content-->
<div class="review">
    <div class="container">
        <div class="games">
            <h2> Terms of Use</h2>
            <div class="main, terms" *ngFor="let termsInfo of termsInfos; let nIndex=index">
                <h3>{{termsInfo.title}}</h3>
                <p *ngFor="let content of termsInfo.content; let niiIndex=index">{{content}}<br></p>
            </div>
        </div>
    </div>
</div>

<app-bottom-bar></app-bottom-bar>