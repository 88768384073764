<!--banner-info-->	
<div class="banner-info">
    <div class="container">
        <div class="logo">
                <h1><a routerLink="/home" routerLinkActive="router-link-active" (click)='onReshuff()'>Candy Casual Games</a></h1>
        </div>
        <div class="top-menu">
            <span class="menu"></span>
            <ul class="nav1">
                <li [ngClass]="{'active' : statu=='home'}"><a routerLink="/home" (click)='onReshuff()'>Home</a></li>
                <li [ngClass]="{'active' : statu=='game'}"><a routerLink="/game" (click)='onReshuff()'>Games</a></li>
                <li [ngClass]="{'active' : statu=='contact'}"><a routerLink="/contact" (click)='onReshuff()'>Contact US</a></li>
            </ul>
        </div>	
<!-- script-for-menu -->
                <script>

                </script>
            <!-- /script-for-menu -->		 
        <div class="clearfix"></div>
    </div>
</div>
<!-- banner -->
<div class="banner">		  			
    <div class="bnr2">						  
    </div>			 
</div>