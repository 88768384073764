<app-top-bar [bannerStatus] = 'bannerStatus'></app-top-bar>
<div class="contact">
    <div class="container">
        <div class="contact-head">
             <h2>Contact US</h2>
             <p style="text-indent: 2em;" *ngFor="let contact of contactUs">{{contact}}<br></p>
             <!-- <form method="post">
                 <div class="col-md-6 contact-left">
                   <input type="text" class="text" name="w3lName" placeholder="Name" required>
                   <input type="email" class="text" name="w3lSender" placeholder="Email" required>
                   <input type="text" class="text" name="w3lSubject" placeholder="Subject" required>
                </div>
                <div class="col-md-6 contact-right">
                        <textarea placeholder="Message" name="w3lMessage"></textarea>
                        <input type="submit" value="SEND"/>
                </div>
            </form> -->
        </div>
        
        <div class="clearfix"></div>
        <!-- <div class="address">
            <h3>Our Locations</h3>
            <div class="locations">				 
                 <ul>
                    <li><span></span></li>					 					
                    <li>
                        <div class="address-info">	
                            <h4>New York, Washington</h4>
                            <p>10-765 MD-Road</p>
                            <p>Washington, DC, United States,</p>
                            <p>Phone: 123 456 7890</p>
                            <p>Mail: <a href="mailto:info@example.com">info(at)example.com</a></p>
                            <h5><a href="">Visit on Google Maps >></a></h5>	
                        </div>
                    </li>				
                 </ul>	
                 <ul>
                    <li><span></span></li>					 					
                    <li>
                        <div class="address-info">	
                            <h4>London, UK</h4>
                            <p>10-765 MD-Road</p>
                            <p>Lorem ipsum, domon sit, UK,</p>
                            <p>Phone: 123 456 7890</p>
                            <p>Mail: <a href="mailto:info@example.com">info(at)example.com</a></p>
                            <h5><a href="">Visit on Google Maps >></a></h5>	
                        </div>
                    </li>				
                 </ul>		
            </div>			 
        </div>		  -->
    </div>
</div>
<app-bottom-bar></app-bottom-bar>