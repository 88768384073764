import { Component, OnInit } from '@angular/core';
import { menuBarInfos, policyInfos } from '../common-info';

@Component({
  selector: 'app-app-policy',
  templateUrl: './app-policy.component.html',
  styleUrls: ['./app-policy.component.css']
})
export class AppPolicyComponent implements OnInit {
  bannerStatus = menuBarInfos.policy;
  constructor() { }

  policyInfos=policyInfos;
  ngOnInit(): void {
  }

}
