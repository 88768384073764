>
<!-- header -->
<div class="top-banner">
    <!--banner-info-->	
    <div class="banner-info">
         <div class="container">
             <div class="logo">
                    <h1><a routerLink="/home" routerLinkActive="router-link-active" (click)='onReshuff()'>Candy Casual Games</a></h1>
             </div>
            <div class="top-menu">
                <span class="menu"></span>
                <ul class="nav1">
                    <li [ngClass]="{'active' : statu=='home'}"><a routerLink="/home" (click)='onReshuff()'>Home</a></li>
                    <li [ngClass]="{'active' : statu=='game'}"><a routerLink="/game" (click)='onReshuff()'>Games</a></li>
                    <li [ngClass]="{'active' : statu=='contact'}"><a routerLink="/contact" (click)='onReshuff()'>Contact US</a></li>
                 </ul>
            </div>	
    <!-- script-for-menu -->
                       <script>
                           
                       </script>
                   <!-- /script-for-menu -->
            
            <div class="clearfix"></div>
        </div>
    </div>
</div>
<!-- banner -->
    <!-- Slider-starts-Here -->
    <script src="../../js/responsiveslides.min.js"></script>
    <div class="slider">
         <div class="callbacks_container">
             <ul class="rslides" id="slider">
                   <div nz-carousel-content *ngFor="let item of topHomeInfo" [ngStyle]="{'background-image': item.img_src }" class="slid">
                        <div class="caption">
                            <h3>{{item.name}}</h3>
                            <p>{{item.description}}</p>
                        </div>
                    </div>
             </ul>
        </div>
    </div>
