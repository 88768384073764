<app-top-bar [bannerStatus]='bannerStatus'></app-top-bar>

<!--content-->
<div class="review">
    <div class="container">
        <div class="games">
            <h2>All Games</h2>
        
            <div class="wrap">	
            <div class="main">
                <ul id="og-grid" class="og-grid">
                    <!--
                    <img class="img-responsive" src="{{gameInfo.data_img_src}}" alt="img01"/>
                    <a href="#" data-largesrc="{{gameInfo.data_src}}" data-title="{{gameInfo.data_img_src}}" data-description="{{gameInfo.data_description}}">
                        <img class="img-responsive" src="{{gameInfo.data_img_src}}" alt="img01"/>
                    </a>
                    -->
                    <li *ngFor="let gameInfo of gameInfos; let nGoodIndex=index">
                        <div class="col-1">
                            <a routerLink="/description" routerLinkActive="router-link-active" (click)='onReshuff()' [queryParams]="{name:gameInfo.data_name}"><img class="img-responsive" src="{{gamePath + gameInfo.data_name + '/'+ gameInfo.data_img_src}}" alt=""></a>
                            <h4 style="overflow: hidden;text-overflow: ellipsis;white-space: nowrap;">{{gameInfo.data_title}}</h4>
                            <p>{{gameInfo.data_introduction}}</p>
                        </div>
                    </li>
                    <div class="clearfix"> </div>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<app-bottom-bar></app-bottom-bar>