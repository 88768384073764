import { PlatformLocation } from '@angular/common';
import { Component, Input, NgModule, OnInit } from '@angular/core';
declare var $: any;
@Component({
  selector: 'app-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.css']
})

export class TopBarComponent implements OnInit {
  @Input() bannerStatus:any;
  statu;
  constructor(location1: PlatformLocation) { 
    location1.onPopState(()=>{
      location.reload();
  });
}

  ngOnChanges(){
    this.statu = this.bannerStatus;
  }

  onReshuff(){
    setTimeout(function(){
      //$("#slider").load(location.href + '#slider');
      location.reload();
    },1);
  }

  ngOnInit(): void {
    $(function(){
      $( "span.menu" ).click(function() {
        $( "ul.nav1" ).slideToggle( 300, function() {
        // Animation complete.
          
            });
      });
    });

  }

}