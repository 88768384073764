import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TopBarComponent } from './top-bar/top-bar.component';
import { TopHomeBarComponent } from './top-home-bar/top-home-bar.component';
import { TopGamesComponent } from './top-games/top-games.component';
import { AppGamesComponent } from './app-games/app-games.component';
import { AppHomeComponent } from './app-home/app-home.component';
import { BottomBarComponent } from './bottom-bar/bottom-bar.component';
import { AppContactComponent } from './app-contact/app-contact.component';
import { AppDescriptionComponent } from './app-description/app-description.component';
import { HashLocationStrategy, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { AppPolicyComponent } from './app-policy/app-policy.component';
import { AppTermsComponent } from './app-terms/app-terms.component';

@NgModule({
  imports: [
    BrowserModule,
    AppRoutingModule
  ],
  declarations: [
    AppComponent,
    TopBarComponent,
    TopHomeBarComponent,
    TopGamesComponent,
    AppGamesComponent,
    AppHomeComponent,
    BottomBarComponent,
    AppContactComponent,
    AppDescriptionComponent,
    AppPolicyComponent,
    AppTermsComponent
  ],
  providers: [{provide: LocationStrategy, useClass: PathLocationStrategy}],
  bootstrap: [AppComponent]
})
export class AppModule { }
