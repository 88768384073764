import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { from } from 'rxjs';
import { AppHomeComponent } from './app-home/app-home.component';
import { AppGamesComponent } from './app-games/app-games.component';
import { AppContactComponent } from './app-contact/app-contact.component';
import { AppDescriptionComponent } from './app-description/app-description.component';
import { AppPolicyComponent } from './app-policy/app-policy.component';
import { AppTermsComponent } from './app-terms/app-terms.component';

const routes: Routes = [
  { path: '', redirectTo : 'home', pathMatch : 'full'},
  { path: 'home', component: AppHomeComponent},
  { path: 'game', component: AppGamesComponent},
  { path: 'contact', component: AppContactComponent},
  { path: 'description', component: AppDescriptionComponent},
  { path: 'policy', component: AppPolicyComponent},
  { path: 'terms', component: AppTermsComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{onSameUrlNavigation:'reload'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
