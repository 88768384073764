<footer id="ac-globalfooter" class="no-js" role="contentinfo" lang="en-US" dir="ltr">
    <div class="ac-gf-content">
        <section class="ac-gf-footer">
            <div class="ac-gf-footer-legal">
                <div class="ac-gf-footer-legal-copyright">Copyright © 2021 Casual Candy All rights reserved.</div>
                <div class="ac-gf-footer-legal-links">
                    <a class="ac-gf-footer-legal-link" routerLink="/policy"
                        data-analytics-title="privacy policy">Privacy Policy</a>
                    <a class="ac-gf-footer-legal-link" routerLink="/terms"
                        data-analytics-title="terms of use">Terms of Use</a>
                </div>
            </div>
        </section>
    </div>
</footer>