import { Component, OnInit } from '@angular/core';
import { $ } from 'protractor';
import { menuBarInfos } from '../common-info';

@Component({
  selector: 'app-app-home',
  templateUrl: './app-home.component.html',
  styleUrls: ['./app-home.component.css']
})
export class AppHomeComponent implements OnInit {
  bannerStatus = menuBarInfos.home;
  constructor() { }

  ngOnInit(): void {
  }

}
