import { Component, OnInit } from '@angular/core';
import { contactUs, menuBarInfos } from '../common-info';

@Component({
  selector: 'app-app-contact',
  templateUrl: './app-contact.component.html',
  styleUrls: ['./app-contact.component.css']
})
export class AppContactComponent implements OnInit {

  bannerStatus = menuBarInfos.contact;
  contactUs = contactUs;
  constructor() { }

  ngOnInit(): void {
    
  }

}
